<template>
    <div
        class="flex flex-col space-y-4 sm:flex-row flex-wrap flex-1 items-start justify-between"
    >
        <div>
            <p class="text-md text-gray-700">
                Showing
                {{ " " }}
                <span class="font-medium">{{ listings.from }}</span>
                {{ " " }}
                to
                {{ " " }}
                <span class="font-medium">{{ listings.to }}</span>
                {{ " " }}
                of
                {{ " " }}
                <span class="font-medium">{{ listings.total }}</span>
                {{ " " }}
                results
            </p>
        </div>
        <TailwindPagination
            v-if="TailwindPagination"
            class="hidden sm:inline-flex"
            :data="listings"
            :limit="2"
            :keepLength="true"
            @pagination-change-page="getResults"
            :item-classes="[
                'text-gray-900',
                'border-gray-300',
                'hover:bg-gray-50',
                'focus:outline-offset-0',
            ]"
            :active-classes="[
                'z-10',
                'bg-bluewood-900',
                'text-white',
                'focus-visible:outline',
                'focus-visible:outline-2',
                'focus-visible:outline-offset-2',
                'focus-visible:outline-cyan-600',
            ]"
        />
        <!-- prev / next buttons for mobile -->
        <div class="flex w-full flex-1 justify-between sm:hidden">
            <Link
                v-if="listings.prev_page_url"
                :href="listings.prev_page_url + '#listing-table'"
                class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >Previous Page</Link
            >
            <Link
                v-if="listings.next_page_url"
                :href="listings.next_page_url + '#listing-table'"
                class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >Next Page</Link
            >
        </div>
    </div>
</template>

<script setup>
import { onMounted, shallowRef } from "vue";
import { Link, router } from "@inertiajs/vue3";
import { defineProps } from "vue";

const props = defineProps({
    listings: {
        type: Object,
        required: true,
    },
});

// visit the page with ?page={id}
const getResults = async (page = 1) => {
    // Get the current query parameters
    const params = new URLSearchParams(window.location.search);

    // Set the new page number
    params.set("page", page);

    // Construct the new URL
    const url = `${props.listings.path}?${params.toString()}#listing-filters`;

    // Navigate to the new URL
    router.get(url);
};

const TailwindPagination = shallowRef(null);

onMounted(() => {
    // Import TailwindPagination only on the client-side
    if (typeof window !== "undefined") {
        import("laravel-vue-pagination")
            .then((module) => {
                TailwindPagination.value = module.TailwindPagination;
            })
            .catch((error) => {
                console.error("Failed to load TailwindPagination:", error);
            });
    }
});
</script>
