<template>
    <div class="relative">
        <ListingsFilters
            class="mx-auto max-w-7xl px-6 lg:px-8"
            ref="filtersComponent"
        />
        <div class="mx-auto max-w-7xl px-6 mt-8 lg:px-8">
            <ListingsTable v-if="listings.total > 0" :listings="listings" />
            <ListingsEmptyState @reset-filters="resetFilters" v-else />
        </div>
    </div>
</template>
<script setup>
import ListingsFilters from "@/Components/Guest/Listings/ListingsFilters.vue";
import ListingsEmptyState from "@/Components/Guest/Listings/ListingsEmptyState.vue";
import ListingsTable from "@/Components/Guest/Listings/ListingsTable.vue";

import { inject, ref } from "vue";

const listings = inject("listings");
const filtersComponent = ref();
const resetFilters = () => {
    filtersComponent.value.resetFilters();
};
</script>
