import { computed, provide, ref, watch } from "vue";
import { usePage } from "@inertiajs/vue3";

const page = usePage();

export default function useListings(props) {
    const listings = ref(props.listings);
    const filters = ref(props.filters);
    const currentRoute = ref(props.currentRoute);

    const filterOptions = ref({
        categories: props.categories,
        experiences: props.experiences,
        commitments: props.commitments,
        remoteStatuses: props.remoteStatuses,
        benefits: props.benefits,
        salaryRanges: props.salaryRanges,
        tags: props.tags,
    });

    provide("listings", listings);
    provide("filters", filters);
    provide("filterOptions", filterOptions);
    provide("currentRoute", currentRoute);

    watch(
        () => props.listings,
        (newListings) => {
            listings.value = newListings;
        },
    );

    watch(
        () => props.filters,
        (newFilters) => {
            filters.value = newFilters;
        },
    );
}
