<template>
    <section class="w-full py-6 md:py-12">
        <div
            class="container grid items-center justify-center gap-4 px-4 text-center md:px-6"
        >
            <div class="space-y-3">
                <h2 class="text-3xl font-bold tracking-tighter sm:text-4xl">
                    Continue browsing thousands of remote jobs in Dubai
                </h2>
                <p
                    class="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400"
                >
                    Subscribe to one of our plans to access all remote jobs in
                    Dubai
                </p>
            </div>
            <div
                class="grid max-w-sm gap-4 mx-auto md:grid-cols-2 md:max-w-3xl md:gap-8"
            >
                <SinglePlan
                    :planType="'monthly'"
                    :planPrice="18.0"
                    :originalPrice="29.0"
                    :isSignUpModalOpen="isSignUpModalOpen"
                    :handleSubscription="handleSubscription"
                />
                <SinglePlan
                    :planType="'yearly'"
                    :planPrice="60.0"
                    :originalPrice="99.0"
                    :isSignUpModalOpen="isSignUpModalOpen"
                    :handleSubscription="handleSubscription"
                />
            </div>
            <!-- already subscribed? sign in now (opens up the same modal) -->
            <p
                v-if="!page.props.user?.id"
                class="text-sm flex mt-4 justify-center items-center gap-4 text-gray-500 dark:text-gray-400"
            >
                Already subscribed?
                <Button
                    class="max-w-sm bg-dxb-bluewood"
                    @click="isSignUpModalOpen = true"
                    >Sign in now</Button
                >
            </p>
            <div class="flex justify-center">
                <ProductHuntEmbed />
            </div>
        </div>
    </section>
    <SignUpModal v-model="isSignUpModalOpen" :plan="plan" />
</template>

<script setup>
import Button from "@/Components/Guest/Button.vue";
import SignUpModal from "@/Components/Guest/SignUpModal.vue";
import { ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import ProductHuntEmbed from "@/Components/Guest/ProductHuntEmbed.vue";
import SinglePlan from "@/Components/Guest/SinglePlan.vue";
import { useGtm } from "@gtm-support/vue-gtm";

const page = usePage();
const gtm = useGtm();
const isSignUpModalOpen = ref(false);

const plan = ref(null);

const handleSubscription = (planType, price) => {
    isSignUpModalOpen.value = true;
    plan.value = planType;
    gtm.trackEvent({
        event: "begin_checkout",
        category: "Subscription",
        action: "click",
        label: planType,
        value: price,
    });
};
</script>
