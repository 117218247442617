<template>
    <Combobox as="div" multiple v-model="selectedItems">
        <div class="relative">
            <ComboboxLabel class="sr-only">{{ label }}</ComboboxLabel>
            <ComboboxButton class="w-full">
                <div class="relative">
                    <!-- Icon on the left -->
                    <div
                        class="absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                        <component
                            :is="icon"
                            :class="[
                                'h-5 w-5',
                                selectedItems.length > 0
                                    ? 'text-cyan-600'
                                    : 'text-gray-500',
                            ]"
                            aria-hidden="true"
                        />
                    </div>

                    <ComboboxInput
                        :placeholder="label"
                        readonly
                        :class="[
                            'w-full rounded-md border truncate bg-white py-2.5 pl-10 pr-10 text-gray-900 placeholder:text-gray-900 focus:outline-none focus:ring-0 cursor-default select-none',
                            selectedItems.length > 0
                                ? 'border-cyan-600'
                                : 'border-gray-200 focus:border-gray-300',
                        ]"
                        @change="query = $event.target.value"
                        :display-value="() => label"
                    />

                    <!-- Selected count indicator -->
                    <div
                        v-if="selectedItems.length > 0"
                        class="absolute inset-y-0 right-8 flex items-center"
                    >
                        <span
                            class="flex h-5 w-5 items-center justify-center rounded-full bg-cyan-600 text-xs text-white"
                        >
                            {{ selectedItems.length }}
                        </span>
                    </div>

                    <!-- Chevron on the right -->
                    <div
                        class="absolute inset-y-0 right-0 flex items-center pr-3"
                    >
                        <ChevronDownIcon
                            :class="[
                                'h-5 w-5',
                                selectedItems.length > 0
                                    ? 'text-cyan-600'
                                    : 'text-gray-400',
                            ]"
                            aria-hidden="true"
                        />
                    </div>
                </div>
            </ComboboxButton>

            <ComboboxOptions
                v-if="filteredOptions.length > 0"
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg border border-gray-200 focus:outline-none"
            >
                <ComboboxOption
                    v-for="option in filteredOptions"
                    :key="option.id"
                    :value="option"
                    as="template"
                    v-slot="{ active, selected }"
                >
                    <li
                        :class="[
                            'relative cursor-default select-none py-2 pl-4 pr-4',
                            active ? 'bg-gray-50' : 'bg-white',
                            selected ? 'text-gray-900' : 'text-gray-600',
                        ]"
                    >
                        <span
                            :class="[
                                'block truncate',
                                selected ? 'font-medium' : 'font-normal',
                            ]"
                        >
                            {{ option.name }}
                        </span>

                        <span
                            v-if="selected"
                            :class="[
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-gray-900' : 'text-gray-600',
                            ]"
                        >
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                    </li>
                </ComboboxOption>
            </ComboboxOptions>
        </div>
    </Combobox>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { CheckIcon, ChevronDownIcon } from "@heroicons/vue/20/solid";
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from "@headlessui/vue";
import {
    BriefcaseIcon,
    ClockIcon,
    UserGroupIcon,
    BuildingOfficeIcon,
    GiftIcon,
} from "@heroicons/vue/24/outline";

const props = defineProps({
    options: {
        type: Object,
        required: true,
    },
    label: {
        type: String,
        required: true,
    },
    modelValue: {
        type: Array,
        default: () => [],
    },
});

const emit = defineEmits(["update:modelValue"]);

const selectedItems = ref(props.modelValue);

// Watch for changes in the modelValue prop
watch(
    () => props.modelValue,
    (newValue) => {
        selectedItems.value = newValue;
    },
    { deep: true },
);

// Watch for changes in selectedItems
watch(
    selectedItems,
    (newValue) => {
        emit("update:modelValue", newValue);
    },
    { deep: true },
);

const options = ref(props.options);
const query = ref("");

// Determine which icon to use based on the label
const icon = computed(() => {
    switch (props.label.toLowerCase()) {
        case "category":
            return BriefcaseIcon;
        case "experience":
            return ClockIcon;
        case "job type":
            return UserGroupIcon;
        case "remote status":
            return BuildingOfficeIcon;
        case "benefits":
            return GiftIcon;
        default:
            return BriefcaseIcon;
    }
});

const filteredOptions = computed(() =>
    query.value === ""
        ? options.value
        : options.value.filter((option) => {
              return option.name
                  .toLowerCase()
                  .includes(query.value.toLowerCase());
          }),
);
</script>
