<template>
    <TransitionRoot appear :show="props.modelValue" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full space-y-4 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <!-- Logo -->
                            <div class="flex justify-between">
                                <Logo :dark="false" />
                                <!-- close button -->
                                <button
                                    type="button"
                                    class="inline-flex justify-center rounded-md border border-transparent bg-white/5 p-1.5 text-gray-400 hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                                    @click="closeModal"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <DialogTitle
                                as="h3"
                                class="sr-only text-xl font-gothic text-center font-medium leading-6 text-gray-900"
                            >
                                Sign In
                            </DialogTitle>

                            <!-- Authentication Buttons -->
                            <div class="space-y-4">
                                <a
                                    :href="
                                        route(
                                            'filament.company.oauth.redirect',
                                            {
                                                provider: 'google',
                                                plan: plan,
                                            },
                                        )
                                    "
                                    class="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    <font-awesome-icon
                                        :icon="faGoogle"
                                        class="w-5 h-5"
                                    />
                                    Sign in with Google
                                </a>
                                <!--                                <a-->
                                <!--                                    :href="-->
                                <!--                                        route(-->
                                <!--                                            'filament.company.oauth.redirect',-->
                                <!--                                            {-->
                                <!--                                                provider: 'twitter-oauth-2',-->
                                <!--                                                plan: plan,-->
                                <!--                                            },-->
                                <!--                                        )-->
                                <!--                                    "-->
                                <!--                                    class="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"-->
                                <!--                                >-->
                                <!--                                    <font-awesome-icon-->
                                <!--                                        :icon="faXTwitter"-->
                                <!--                                        class="w-5 h-5"-->
                                <!--                                    />-->
                                <!--                                    Sign in with X-->
                                <!--                                </a>-->
                                <a
                                    :href="
                                        route(
                                            'filament.company.oauth.redirect',
                                            {
                                                provider: 'facebook',
                                                plan: plan,
                                            },
                                        )
                                    "
                                    class="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    <font-awesome-icon
                                        :icon="faFacebook"
                                        class="w-5 h-5"
                                    />
                                    Sign in with Facebook
                                </a>
                                <a
                                    :href="
                                        route(
                                            'filament.company.oauth.redirect',
                                            {
                                                provider: 'linkedin-openid',
                                                plan: plan,
                                            },
                                        )
                                    "
                                    class="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    <font-awesome-icon
                                        :icon="faLinkedin"
                                        class="w-5 h-5"
                                    />
                                    Sign in with LinkedIn
                                </a>
                            </div>

                            <!-- or sign in/sign up via email -->
                            <div class="space-y-4">
                                <a
                                    :href="
                                        route('filament.user.auth.register', {
                                            plan: plan,
                                            redirect: 1,
                                        })
                                    "
                                    class="w-full flex justify-center items-center gap-2 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                                >
                                    <font-awesome-icon
                                        :icon="faEnvelope"
                                        class="w-5 h-5"
                                    />
                                    Sign up with Email
                                </a>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";
import Logo from "@/Components/Guest/Logo.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faGoogle,
    faFacebook,
    faXTwitter,
    faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { inject } from "vue";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
const route = inject("route");

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
    modelValue: Boolean,
    plan: String,
});

function closeModal() {
    emit("update:modelValue", false);
}
</script>
