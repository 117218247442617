<template>
    <div class="bg-gray-100 rounded-lg">
        <div
            class="mx-auto max-w-7xl px-4 py-3 flex flex-wrap items-center gap-2 sm:px-6 lg:px-8"
        >
            <h3 class="text-sm py-2 font-medium text-gray-500">
                Filters
                <span class="sr-only">, active</span>
            </h3>

            <div aria-hidden="true" class="h-5 w-px bg-gray-300 mx-2" />

            <div class="flex flex-wrap items-center">
                <span
                    v-for="(activeFilter, key) in activeFiltersArray"
                    :key="key"
                    class="m-1 inline-flex items-center rounded-full border border-gray-200 bg-white py-1.5 pl-3 pr-2 text-sm font-medium text-gray-900"
                >
                    <span>{{ activeFilter?.name }}</span>
                    <button
                        @click="
                            $emit('remove-filter', {
                                type: activeFilter.type,
                                id: activeFilter.id,
                            })
                        "
                        type="button"
                        class="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-200 hover:text-gray-500"
                    >
                        <span class="sr-only"
                            >Remove filter for {{ activeFilter?.name }}</span
                        >
                        <svg
                            class="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                            />
                        </svg>
                    </button>
                </span>
            </div>

            <button
                @click="$emit('reset-filters')"
                type="button"
                class="flex-shrink-0 inline-flex items-center capitalize rounded-md bg-bluewood-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-bluewood-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bluewood-600"
            >
                Reset all filters
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    activeFilters: {
        type: Object,
        required: true,
    },
});

const activeFiltersArray = computed(() => {
    // Loop through arrayFilters and return all the id and names of the active filters
    return Object.entries(props.activeFilters)
        .filter(([key, filter]) => filter.length > 0)
        .map(([key, filter]) => {
            return filter.map((item) => {
                return {
                    type: key, // the object key
                    id: item.id,
                    name: item.name,
                };
            });
        })
        .flat();
});
</script>
