<template>
    <a
        href="https://www.producthunt.com/posts/remote-dxb?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-remote&#0045;dxb"
        target="_blank"
        ><img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=447933&theme=light"
            alt="Remote&#0032;DXB - Dubai&#0039;s&#0032;first&#0032;remote&#0032;and&#0032;hybrid&#0032;jobs&#0032;platform&#0033; | Product Hunt"
            style="width: 250px; height: 54px"
            width="250"
            height="54"
    /></a>
</template>

<script setup></script>
