<template>
    <div id="listing-table" class="space-y-4">
        <div class="relative">
            <ul role="list" class="flex flex-col gap-6">
                <template
                    v-for="(listing, index) in listings.data"
                    :key="listing.id"
                >
                    <ListingItem :listing="listing" />
                    <li
                        v-if="index === 1 || index === 4 || index === 7"
                        class="bg-gradient-to-r from-cyan-500 to-blue-600 rounded-xl"
                    >
                        <ListingAdvert />
                    </li>
                </template>
            </ul>
            <ListingsPagination
                v-if="
                    (listings.total > 0 && page.props.user.subscribed) ||
                    page.props.isBot
                "
                :listings="listings"
            />
            <div v-else>
                <div
                    class="absolute bottom-0 left-0 w-full h-48 bg-gradient-to-b to-white/100 via-white/80 from-white/70"
                ></div>
            </div>
        </div>
        <Plans v-if="!page.props.user.subscribed" />
    </div>
</template>

<script setup>
import ListingItem from "@/Components/Guest/Listings/ListingItem.vue";
import ListingsPagination from "@/Components/Guest/Listings/ListingsPagination.vue";
import { usePage } from "@inertiajs/vue3";
import Plans from "@/Components/Guest/Plans.vue";
import ListingAdvert from "@/Components/Guest/Listings/ListingAdvert.vue";

const page = usePage();

defineProps({
    listings: {
        type: Object,
        required: true,
    },
});
</script>
